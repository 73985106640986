// 79909
@import '../helpers/componentHeader';
@import '../components/buttons';
// 64614
.video-carousel{
    margin: 0 -15px;
    overflow-x: hidden;
    padding: 67px 0 128px 0;
    background: $primary;
    @include media-breakpoint-down(lg){
        margin: 0px;
    }
    @include media-breakpoint-down(sm){
        padding: 40px 0px 90px;

    }

    .video-carousel__wrapper{
        width: 80%;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 15px;
        
        .linebar-heading{ 
            text-align: center;
            width: auto;
            margin: 0;            
            font-family: $secondary-font-medium;
            font-size: 18px;
            line-height: 26px;
            color: $white;
            font-weight: normal;
            letter-spacing: 0;
            @include media-breakpoint-up(md) {
                text-align: center;
                font-size: 36px;
                line-height: 46px;
            }
            
            &:after{
                content:'';
                height: 2px;
                width:64px;
                background: $yellow;
                margin-top:7px;
                margin-bottom: 40px;
                margin-right: auto;
                margin-left: auto;
                display: block;
                @include media-breakpoint-up(md) {
                    margin-top:16px;
                    margin-bottom: 60px;
                }
                @include media-breakpoint-down(sm){
                    margin-bottom: 20px;
                    width: 36px;
                }
            }
        }
        .video-carousel-list{
            .video-carousel-list__item{
                .vertical-video-article{
                    .video-carousel-title{
                        font-family: $secondary-font-medium;
                        font-size: 18px;
                        line-height: 28px;
                        color: $white;
                        margin-bottom: 15px;
                        padding: 0px 2px;

                        @include media-breakpoint-up(md) {
                            font-size: 28px;
                            line-height: 36px;
                            margin-bottom: 30px;
                        }
                    }
                    .video-carousel-description{
                        font-size: 18px;
                        line-height: 32px;
                        font-family: $primary-font;
                        margin-bottom: 0px;
                        color: $white;
                        padding: 0px 2px;
                        @include media-breakpoint-down(sm) {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 0;
                        }
                    }
                    .video-carousel-thumbnail{
                        position: relative;
                        min-height: 60px;
                        margin-bottom: 40px;

                        .video-mask {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(0, 0, 0, 0.3);
                            -webkit-transition: all 0.3s ease;
                            -ms-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                        .btn-video-popup {
                            background: url("../../images/play-video.svg");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            width: 50px;
                            height: 50px;
                            border: 0px;
                            position: absolute;
                            margin: auto;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
        
    }

    .slick-prev {
        @include sliderBtns(left);
        background: url(../../images/arrow-white.svg) no-repeat;
        background-size: cover;
        transform-origin: center 7px;
        transform: rotate(180deg);
        height: 25px;
        width: 14px;
        left: -45px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;

        &:before{
            display: none;
        }
        @include media-breakpoint-up(md) {               
            height: 41px;
            width: 22px;
            transform-origin: center 11px;
        }

        @include media-breakpoint-down(md) {
            left: -40px;
        }
        @include media-breakpoint-down(sm) {
            left: -25px;
        }
        @include media-breakpoint-up(xl) {
            left: -155px;
            height: 41px;
            width: 22px;
            transform-origin: center 11px;
        }

        &.slick-disabled{
            opacity: 0.5;
            pointer-events: none;
        }

    }
    .slick-next {
        @include sliderBtns(right);
        background: url(../../images/arrow-white.svg) no-repeat;
        background-size: cover;
        height: 25px;
        width: 14px;
        right: -45px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;

        &:before{
            display: none;
        }
        @include media-breakpoint-up(md) {               
            height: 41px;
            width: 22px;
        }
        @include media-breakpoint-down(md) {
            right: -40px;
            
        }
        @include media-breakpoint-down(sm) {
            right: -25px;
        }
        @include media-breakpoint-up(xl) {
            right: -155px;
            height: 41px;
            width: 22px;
        }
        &.slick-disabled{
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .slick-dots {     
        white-space: nowrap;
        margin: auto;
        bottom: -60px;
        right: 0;
        left: 0;

        li {
            &.slick-active button:before {
                color: $pastel-orange;
            }

            width: 8px;
            height: 8px;

            button {
                @include sliderBtns('', '', 15px);
                width: 12px;
                height: 12px;
                padding: 0;

                &:before{
                    color: $midgray;
                    opacity: 1;
                    font-size: 12px;
                    line-height: 12px;
                    width: 12px;
                    height: 12px;
                }

                &:hover{
                    &:before{
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                @include media-breakpoint-up(md) {
                    width: 8px;
                    height: 8px;
                    padding: 0;
                    @include sliderBtns('', '', 25px);
                    &:before{
                        color: $midgray;
                        opacity: 1;
                        font-size: 12px;
                        line-height: 12px;
                        width: 12px;
                        height: 12px;
                    }

                    &:hover{
                        &:before{
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
}

.video-carousel-title__modal{
    font-family: $secondary-font-medium;
    font-size: 16px;
    line-height: 26px;
    color: $primary;
    margin-bottom: 10px;
    margin-top: 20px;   

    @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}
.video-carousel-description__modal{
    font-size: 12px;
    line-height: 22px;
    font-family: $primary-font-semibold;
    margin-bottom: 0px;
    color: $primary;
    @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 0;
    }
}